import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { MotionValue, motion, useScroll, useTransform } from 'framer-motion'

import vission1 from '@/assets/images/home/vission/1.png'
import vission2 from '@/assets/images/home/vission/2.png'
import vission3 from '@/assets/images/home/vission/3.png'
import vission4 from '@/assets/images/home/vission/4.png'
import vission5 from '@/assets/images/home/vission/5.png'
import vission6 from '@/assets/images/home/vission/6.png'
import vission7 from '@/assets/images/home/vission/7.png'
import vission8 from '@/assets/images/home/vission/8.png'
import vission9 from '@/assets/images/home/vission/9.png'
import vission10 from '@/assets/images/home/vission/10.png'

import { DeviceType, useDeviceType } from '@/hooks/useDeviceType'

import { media } from '@/styles/media'
import { useTranslation } from 'react-i18next'
import SubTitle from './Subtitle'

const topImages = [
  { src: vission1, alt: '' },
  { src: vission2, alt: '' },
  { src: vission3, alt: '' },
  { src: vission4, alt: '' },
  { src: vission5, alt: '' }
]

const bottomImages = [
  { src: vission6, alt: '' },
  { src: vission7, alt: '' },
  { src: vission8, alt: '' },
  { src: vission9, alt: '' },
  { src: vission10, alt: '' }
]

const Wrapper = styled.div`
  display: flex;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  padding: 128px 96px 32px 96px;
  gap: 31px;
  overflow-x: hidden;

  ${media.bigDesktopDown} {
    padding: 128px 64px 32px 64px;
  }

  ${media.tabletLandscapeDown} {
    padding: 88px 24px 0 24px;
    margin-top: -8px;
  }
`

const TitleWrapper = styled.div`
  position: relative;

  padding: 128px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &:before {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    content: '日本体験';
    color: #e2421f;
    font-family: 'Zen Antique Soft';
    font-style: normal;
    font-size: 256px;
    font-weight: 400;
    line-height: 256px;

    text-transform: uppercase;
    opacity: 0.15;
    mix-blend-mode: hard-light;
  }

  ${media.largeDesktopDown} {
    padding: 128px 0;
  }

  ${media.newDesktopDown} {
    padding: 0;

    &:before {
      font-size: 110.943px;
      line-height: 120px;
      letter-spacing: -1.2px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
`

const ImagesWrapper = styled(motion.div)<{ alignLeft?: boolean }>`
  display: flex;
  z-index: 1;
  gap: 24px;
  justify-content: center;
  transform: translateX(${({ alignLeft }) => (alignLeft ? '-18%' : '18%')});

  ${media.tabletLandscapeDown} {
    justify-content: start;
    gap: 16px;
  }
`

const Title = styled.h4`
  margin: 0;
  text-align: center;
  color: #001b2e;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 72px;
  letter-spacing: -0.96px;
  word-break: keep-all;
  ${media.tabletLandscapeDown} {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.44px;
  }
`

const StyledImage = styled.img`
  width: 24.1%;
  border-radius: 24px;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: right;

  ${media.tabletPortraitDown} {
    width: 45%;
    border-radius: 16px;
  }
`

function TopImages({ progress }: { progress: MotionValue<number> }) {
  const { deviceType } = useDeviceType()

  const range = deviceType !== DeviceType.Mobile ? [0, 1] : [0.3, 0.5]
  const style =
    deviceType !== DeviceType.Mobile ? ['18%', '-18%'] : ['0%', '-150%']

  const x = useTransform(progress, range, style)

  return (
    <ImagesWrapper style={{ x }}>
      {topImages.map((el) => (
        <StyledImage {...el} key={el.src} />
      ))}
    </ImagesWrapper>
  )
}

function BottomImages({ progress }: { progress: MotionValue<number> }) {
  const { deviceType } = useDeviceType()

  const range = deviceType !== DeviceType.Mobile ? [0, 1] : [0.5, 0.8]
  const style =
    deviceType !== DeviceType.Mobile ? ['-18%', '18%'] : ['-150%', '0%']

  const x = useTransform(progress, range, style)

  return (
    <ImagesWrapper alignLeft style={{ x }}>
      {bottomImages.map((el) => (
        <StyledImage {...el} key={el.src} />
      ))}
    </ImagesWrapper>
  )
}

export default function OurVision() {
  const ref = useRef()
  const { t } = useTranslation()

  const { scrollYProgress: scrollY } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })

  return (
    <Wrapper ref={ref}>
      <TopImages progress={scrollY} />
      <TitleWrapper>
        <SubTitle translate="no">
          <span>Our VISION</span>
          <span>私たちのビジョン</span>
        </SubTitle>
        <Title>{t('home.vission')}</Title>
      </TitleWrapper>
      <BottomImages progress={scrollY} />
    </Wrapper>
  )
}
