import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { MotionValue, motion, useScroll, useTransform } from 'framer-motion'

import CtaButton from '@/components/generic/CtaButton'
import { Icon } from '@/components/generic/Icon'

import workLife1 from '@/assets/images/home/work-life/1.jpeg'
import workLife2 from '@/assets/images/home/work-life/2.jpeg'
import workLife3 from '@/assets/images/home/work-life/3.jpeg'
import workLife4 from '@/assets/images/home/work-life/4.jpeg'
import workLife5 from '@/assets/images/home/work-life/5.jpeg'
import workLife6 from '@/assets/images/home/work-life/6.jpeg'
import workLife7 from '@/assets/images/home/work-life/7.jpeg'
import workLife8 from '@/assets/images/home/work-life/8.jpeg'

import globeIcon from '@/assets/images/icons/globe.svg'
import clockIcon from '@/assets/images/icons/clock.svg'
import umbrella from '@/assets/images/icons/umbrella.svg'
import waterfallCart from '@/assets/images/icons/waterfall_chart.svg'
import contactMail from '@/assets/images/icons/contact_mail.svg'
import airplaneTicket from '@/assets/images/icons/airplane_ticket.svg'
import route from '@/assets/images/icons/route.svg'
import confetti from '@/assets/images/icons/confetti.svg'
import diversity from '@/assets/images/icons/diversity.svg'
import apparel from '@/assets/images/icons/apparel.svg'

import { media } from '@/styles/media'
import { useTranslation } from 'react-i18next'
import { useDeviceType } from '@/hooks/useDeviceType'
import SubTitle from './Subtitle'

const images = [
  {
    src: workLife1,
    width: 55,
    margin: -10
  },
  {
    src: workLife2,
    width: 40
  },
  {
    src: workLife3,
    width: 40,
    margin: -15
  },
  {
    src: workLife4,
    width: 35
  },
  {
    src: workLife5,
    width: 40
  },
  {
    src: workLife6,
    width: 30,
    margin: -15
  },
  {
    src: workLife7,
    width: 40
  },
  {
    src: workLife8,
    width: 35
  }
]

const topImages = [
  { src: workLife1, alt: '', width: 40 },
  { src: workLife7, alt: '', width: 70 },
  { src: workLife8, alt: '', width: 40 },
  { src: workLife2, alt: '', width: 70 }
]

const bottomImages = [
  { src: workLife3, alt: '', width: 70 },
  { src: workLife4, alt: '', width: 40 },
  { src: workLife6, alt: '', width: 40 },
  { src: workLife5, alt: '', width: 70 }
]

const benefits = [
  {
    icon: globeIcon
  },
  {
    icon: clockIcon
  },
  {
    icon: umbrella
  },
  {
    icon: waterfallCart
  },
  {
    icon: contactMail
  },
  {
    icon: airplaneTicket
  },
  {
    icon: route
  },
  {
    icon: confetti
  },
  {
    icon: diversity
  },
  {
    icon: apparel
  }
]

type BenefitType = typeof benefits[0]

const Wrapper = styled(motion.div)`
  display: flex;
  margin: 96px 10px 0 10px;
  flex-direction: row;
  gap: 56px;
  align-items: center;
  height: 640px;
  overflow-y: hidden;

  border: 2px solid rgba(168, 136, 235, 0.2);
  border-radius: 48px;

  ${media.newDesktopDown} {
    height: auto;
    border-radius: 24px;
    padding: 48px 16px;
    margin: 0 96px !important;
  }

  ${media.tabletLandscapeDown} {
    margin: 0 24px !important;
  }
`

const MobileWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${media.tabletLandscapeDown} {
    gap: 16px;
    padding-bottom: 56px;
  }
`

const MobileImagesWrapper = styled(motion.div)<{ alignLeft?: boolean }>`
  display: flex;
  z-index: 1;
  justify-content: start;
  gap: 8px;
  transform: translateX(${({ alignLeft }) => (alignLeft ? '-18%' : '18%')});

  ${media.newDesktop} {
    display: none;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
`

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;

  max-width: calc(50vw - 28px - 96px - 58px);
  ${media.newDesktopDown} {
    max-width: unset;
    align-items: center;
  }
`

const TextWrapper = styled.div`
  display: flex;

  flex-direction: column;
  gap: 24px;

  ${media.newDesktopDown} {
    align-items: center;
  }
`

const Title = styled.h3`
  color: #9074cb;
  leading-trim: both;
  margin: 0;
  font-size: 44px;
  font-style: normal;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: -1.76px;
  word-break: keep-all;
  ${media.newDesktopDown} {
    text-align: center;
  }
  ${media.tabletLandscapeDown} {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.96px;
  }
`

const Items = styled(motion.div)`
  display: flex;
  flex: 1;
  height: auto;
  padding: 32px 0;
  gap: 24px;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  transform: translateY(13%);

  ${media.newDesktopDown} {
    display: none;
  }
`

const PillWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  ${media.newDesktopDown} {
    justify-content: center;
  }
`

const Pill = styled.div`
  display: flex;

  border-radius: 198px;
  border: 0.55px solid #999b9f;
  background: #fff;
  backdrop-filter: blur(2.200000047683716px);
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15.5px 17.5px;

  color: #1e1e1f;
  font-size: 17.6px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.6px;
  letter-spacing: -1.056px;

  ${media.tabletLandscapeDown} {
    font-size: 12px;
    line-height: 17.6px;
    letter-spacing: -0.72px;
    padding: 8px 12px;
  }
`

const Image = styled.img<{ width: number; margin?: number }>`
  border-radius: 31px;
  box-shadow: 49.92px 50.726px 20.129px 0px rgba(0, 0, 0, 0),
    32.207px 33.012px 18.519px 0px rgba(0, 0, 0, 0.01),
    17.714px 18.519px 15.298px 0px rgba(0, 0, 0, 0.05);
  object-fit: cover;
  height: 250px;
  width: calc(${({ width }) => width}% - 24px);
  margin-left: ${({ margin }) => margin}%;

  ${media.newDesktopDown} {
    border-radius: 12px;
  }

  ${media.tabletPortraitDown} {
    height: 120px;
  }
`

function MobileTopImages({ progress }: { progress: MotionValue<number> }) {
  const range = [0.2, 0.4]
  const style = ['-75%', '30%']

  const x = useTransform(progress, range, style)

  return (
    <MobileImagesWrapper style={{ x }}>
      {topImages.map((el) => (
        <Image {...el} key={el.src} />
      ))}
    </MobileImagesWrapper>
  )
}

function MobileBottomImages({ progress }: { progress: MotionValue<number> }) {
  const range = [0.5, 0.8]
  const style = ['30%', '-75%']

  const x = useTransform(progress, range, style)

  return (
    <MobileImagesWrapper alignLeft style={{ x }}>
      {bottomImages.map((el) => (
        <Image {...el} key={el.src} />
      ))}
    </MobileImagesWrapper>
  )
}

function Benefit({ icon, idx }: BenefitType & { idx: number }) {
  const { t } = useTranslation()

  return (
    <Pill>
      <Icon src={icon} />
      {t(`home.career.labels.${idx}`)}
    </Pill>
  )
}

export default function WorkLifeAtIchigo() {
  const ref = useRef()
  const { t } = useTranslation()
  const { width } = useDeviceType()

  const { scrollYProgress: scrollY } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })

  const y = useTransform(scrollY, [0, 0.8], ['-15%', '-40%'])
  const margin = useTransform(
    scrollY,
    [0.3, 0.7],
    [
      '96px 10px 0 10px',
      width <= 1366 ? '96px 64px 0px 64px' : '96px 96px 0px 96px'
    ]
  )

  return (
    <MobileWrapper>
      <MobileTopImages progress={scrollY} />
      <Wrapper ref={ref} style={{ margin }}>
        <Items style={{ y }}>
          {images.map((el) => (
            <Image {...el} key={el.src} />
          ))}
        </Items>
        <ContentWrapper>
          <InnerContent>
            <TextWrapper>
              <SubTitle center translate="no">
                <span>WORK AND LIFE AT ICHIGO</span>
                <span>ICHIGOでの仕事と生活</span>
              </SubTitle>
              <Title>{t('home.career.title')}</Title>
            </TextWrapper>
            <PillWrapper>
              {benefits.map((el, idx) => (
                <Benefit {...el} key={el.icon} idx={idx} />
              ))}
            </PillWrapper>
            <CtaButton
              to="https://www.linkedin.com/company/ichigoinc/"
              target="_blank"
              text={t('menu.careers')}
              bgColor="#064A79"
              color="#F8F8F8"
            />
          </InnerContent>
        </ContentWrapper>
      </Wrapper>
      <MobileBottomImages progress={scrollY} />
    </MobileWrapper>
  )
}
