import { LottieComponentProps } from 'lottie-react'
import React, { ReactNode, Suspense, lazy, useEffect, useState } from 'react'

const LazyLottieComponent = lazy(() => import('lottie-react'))

interface LottieProps {
  id: string
  loadingElement: ReactNode
  assetpath: string
}

export default function LazyLottie({
  loadingElement,
  assetpath,
  id,
  ...props
}: LottieProps & Omit<Omit<LottieComponentProps, 'ref'>, 'animationData'>) {
  const [loading, setLoading] = useState(true)
  const [animationData, setAnimatedData] = useState(null)

  useEffect(() => {
    if (!assetpath) return undefined

    const controller = new AbortController()
    const { signal } = controller

    const handler = setTimeout(() => {
      setLoading(true)

      fetch(assetpath, { signal })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          setAnimatedData(data)
        })
        .finally(() => setLoading(false))
    }, 500)

    function cleanup() {
      clearTimeout(handler)
      controller.abort()
    }

    return cleanup
  }, [assetpath])

  return (
    <Suspense fallback={loadingElement || null}>
      {loading && loadingElement}
      <LazyLottieComponent animationData={animationData} id={id} {...props} />
    </Suspense>
  )
}
